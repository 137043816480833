import { LoaderFunctionArgs, redirect } from "react-router-dom";
import authProvider from "../../providers/auth.provider";

const redirectToLogin = (request: Request) => {
  let params = new URLSearchParams();
  params.set("from", new URL(request.url).pathname);
  return redirect("/?" + params.toString());
}

const fetchProfileIfNotCached = async () => {
  if (!authProvider.hasCheckedAuthenticated) {
    await authProvider.fetchProfile();
  }
}

const checkIsAuthenticated = async (request: Request) => {
  if (!authProvider.isAuthenticated) {
    return redirectToLogin(request);
  }
}

export const protectedHasOneOfRoleLoader = (roles: string[]) => {
  return async ({ request }: LoaderFunctionArgs) => {
    await fetchProfileIfNotCached();
    await checkIsAuthenticated(request);

    let hasRole = false;
    for (const role of roles) {
      if (authProvider.user?.permissions[role]) {
        hasRole = true;
      }
    }

    if (!hasRole) {
      return redirectToLogin(request);
    }

    return null;
  }
}

export const protectedByRoleLoader = (roles: string[]) => {
  return async ({ request }: LoaderFunctionArgs) => {
    await fetchProfileIfNotCached();
    await checkIsAuthenticated(request);

    let hasAllRoles = true;

    for (const role of roles) {
      if (!authProvider.user?.permissions[role]) {
        hasAllRoles = false;
      }
    }

    if (!hasAllRoles) {
      return redirectToLogin(request);
    }

    return null;
  }
}